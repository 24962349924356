import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';

import AboutCaitlin from '../components/AboutCaitlin';
import CoachingOptions from '../components/CoachingOptions';
import Contact from '../components/Contact';
import GetCurious from '../components/GetCurious';
import HowItWorks from '../components/HowItWorks';
import Testimonials from '../components/Testimonials';
import WonderWhatsNext from '../components/WonderWhatsNext';
import WonderYourWay from '../components/WonderYourWay';
import AreYouMissoungOut from '../components/AreYouMissoungOut';
import HeaderComponent from '../components/HeaderComponent';
import headerImg from '../assets/images/header.jpg';

const Homepage = () => {
  const siteTitle = 'Home | Experiment With Wonder';

  return (
    <Layout>
      <Helmet title={siteTitle}>
      </Helmet>
      <HeaderComponent
        buttonType="coaching"
        backgroundImg={headerImg}
      >
        <strong>Transformational Coaching<br /><span className="space">&nbsp;</span>to Explore&nbsp;Life’s&nbsp;Possibilities</strong>
      </HeaderComponent>
      <GetCurious />
      <AreYouMissoungOut />
      <WonderWhatsNext />
      <WonderYourWay />
      <CoachingOptions />
      <HowItWorks />
      {/* <EBookGiveaway /> */}
      <Testimonials />
      <AboutCaitlin />
      <Contact />
    </Layout>
  );
}

export default Homepage;